import React, { useState } from "react";

const Button = (props) => {
    const id = props.id;
    const util = props.util;
    const color = props.color;

    var btn_color = {};
    const [hover, isHover] = useState(false);
    if (hover === true) {
        btn_color = color.GREY_EIGHT;
    } else {
        btn_color = color.GREY_TEN;
    }

    return <>
        <button onClick={util} 
            onMouseEnter={() => isHover(true)} 
            onMouseLeave={() => isHover(false)} 
            id={id} style={{
                backgroundColor: btn_color,
                border: `2px solid ${color.GREY_FIVE}`,
            }} ></button>
    </>
}
export default Button