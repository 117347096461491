import React from "react";

const Title = (props) => {
    const i = props.i;
    const id = props.id;
    const color = props.color;
    const data = {
        w: props.w || 15,
        h: props.h || 4,
    }
    
    return <>
        <button id={id} style={{
            width: `${data.w}rem`,
            height: `${data.h}rem`,
            fontSize: '3rem',
            backgroundColor: color.GREY_TEN,
            color: color.GREY_ONE,
        }}>{i}</button>
    </>
}
export default Title