import React, { useState } from "react";
import About from "./About";
import Button from "./Button";
import Title from "./Title";

const Landing = () => {

    var height = window.innerHeight;

    var color = {};
    const [dark, setDark] = useState(false);
    const toggleDark = () => {
        if(dark === false) {
            setDark(true)
        } else {
            setDark(false)
        }
    };
    switch(dark) {
        case true: color = {
                GREY_ONE: '#212121',
                GREY_FIVE: '#9E9E9E',
                GREY_EIGHT: '#BDBDBD',
                GREY_TEN: '#E0E0E0',
                HIGH_ONE: '#FAFAFA',
                HIGH_TWO: '#E0E0E0',
        }
        break;
        case false: color = {
                GREY_ONE: '#E0E0E0',
                GREY_FIVE: '#BDBDBD',
                GREY_EIGHT: '#424242',
                GREY_TEN: '#212121',
                HIGH_ONE: '#424242',
                HIGH_TWO: '#9E9E9E',
        }
        break;
        default: setDark(false);
    }
    return <>
        <div style={{
            height: height,
            backgroundColor: color.GREY_TEN,
        }} >
            <Button util={toggleDark} color={color} id="btn_night" />
            <Title i='Taylor McI' color={color} id="btn_title" />
            <About color={color} />
        </div>
    </>
}

export default Landing;
