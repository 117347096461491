import React from "react";

const About = (props) => {
    const color = props.color;

    return <>
        <p id='p' style={{
            color: color.GREY_ONE,
        }} >
            Recent graduate of Business Management Marketing.
        </p>
         <p id='p' style={{
            color: color.GREY_ONE,
        }} >
            Hobbiest Programmer.
        </p>
        <p id='p' style={{
            color: color.GREY_ONE,
        }}>
            ಠ_ಠ
        </p>
    </>
}
export default About;
